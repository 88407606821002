.PaymentCCScreen {
  .ccfields {
    margin-top: @spacer-base;
  }

  .input-group--neo {
    height: 63px;
  
    & > span {
      height: 20px;
      display: block;
    }
  }
}


.invisible-frame {
  iframe {
    visibility: hidden;
  }
}

.visible-frame {
  iframe {
    visibility: visible;
  }
}

.fee-info {
  color: @wv-orange;
  cursor: pointer;

  &:hover {
    color: @wv-dark-orange;
  }
}