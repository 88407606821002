.Widget {
  position: relative;
  width: var(--widget-width);
  height: var(--widget-height);
  overflow: hidden;
}

.screen {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: var(--app-width);
  height: var(--app-height);
}

.screen {
  .bottom-item {
    margin-top: auto;
  }
}

.screen-content {
  display: flex;
  flex-direction: column;
  padding: 0 @spacer-xl;
}

.screen-footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  padding: 0 @spacer-xl;
  padding-bottom: @spacer-xl;
}

.widget-nav {
  background: #fff;
  padding: @spacer-xs @spacer-base;
  font-size: @font-size-centi;
  line-height: @line-height-centi;

  button {
    background: transparent;
    border: none;
    color: @wv-blue;
  }
}
