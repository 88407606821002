#payment-methods {
  display: flex;
  flex-direction: column;
  margin-top: @spacer-xl;

  label {
    width: 100%;
    text-align: left;
    margin: 0;
    margin-bottom: @spacer-xs;

    .paymentIcon {
      color: @wv-grey;
    }

    &.is-selected {
      .paymentIcon {
        color: @wv-lightest-grey;
      }  
    }
  }
}

.current-payment-method {
  margin: 0;
  margin-top: @spacer-base;

  p {
    margin: @spacer-xxs 0;
    padding: 0;
  }

  h4 {
    font-weight: @font-weight-medium;
    font-size: @font-size-deci;
    line-height: @line-height-deci;
    padding: 0;
    margin: 0;
    margin: @spacer-xxs 0;
  }

  .paymentIcon {
    font-size: @font-size-zeta;
  }
}