.PromoteMonthlyScreen {
  h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2em;
    margin: 7px 0 10px 0;
  }

  p {
    font-size: 14px;
    line-height: 1.2em;
    margin: 0 0 12px 0;
  }

  .gift-icon {
    color: @wv-orange-20;
    font-size: @font-size-delta;
    position: absolute;
    left: 12px;
    top: 11px;
  }
  
  .btn {
    margin-bottom: @spacer-xs;
    
    &.btn-secondary {
      background: #00acca;
    }

  }

}
