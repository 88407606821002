.App {
  margin: 0 auto;
  max-width: 100%;
  text-align: center;
}

// .form-group--neo.has-error .form-control-wrapper .form-control--neo {
//   border-radius: 8px;
// }

.spinner--wv-star {
  margin: 70px auto;
}