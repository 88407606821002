// From the prototype

@screen-xxs: 360px;
@screen-xxxs: 320px;

:root {
  --wv-orange: @wv-orange;
}

.donate-widget__title {
  font-size: 18px;
  margin:  0 0 @spacer-base 0;
  text-align: center;
}

.giving-form--neo {
  width: var(--app-width);
  height: var(--app-height);
  display: flex;
  flex-direction: column;
  border-radius: 0;
  border: none;
  margin: 0;
}

.giving-form__frequency-select {
  margin-bottom: @spacer-xl;
}

// class to scale down fonts if options are longer than 4 digits
.price-array__option.price-array__option--long-label {
  font-size: @font-size-centi;
  padding: @spacer-tight @spacer-tight;
}

// class to wrap the price array & input field
// requires that the price array radios be in the same parent wrapper as the form group
.giving-form__amount-select--wrapped {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, max-content);
  grid-gap: @spacer-tight;
  align-items: center;

  .form-group {
    align-self: stretch;
    grid-column: span 3;
    height: 100%;
  }

  &.price-options--4 {
    grid-template-columns: repeat(3, 1fr);
    .form-group {
      grid-column: span 2;
    }
  }
  
  &.price-options--4.price-options---no-other-amount {
    grid-template-columns: repeat(2, 1fr);
  }

  &.price-options--3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &.price-options--2 {
    grid-template-columns: repeat(2, 1fr);
    .form-group {
      grid-column: span 2;
    }
  }

  &.price-options--1 {
    grid-template-columns: repeat(3, 1fr);
    .form-group {
      grid-column: span 2;
    }
  }

  &.price-options--1.price-options---no-other-amount {
    grid-template-columns: repeat(1, 1fr);
  }

  &.price-options---no-other-amount {
    .giving-form__open-dollar {
      display: none;
    }
  }

  .price-array__option, .form-group {
    margin: 0;
  }
  

  
  .input-group, .form-control-wrapper, .form-control {
    height: inherit;
  }
}

.giving-form__open-dollar {
  margin-bottom: @spacer-xl;
}

.giving-form__dedication {
  margin-bottom: @spacer-xl;
  margin-top: 0;
}

.dedication__icon {
  color: @wv-orange;
  margin-left: @spacer-xs;
}

.giving-form__secure {
  color: @gray-dark;
  font-style: italic;
  margin-bottom: 0;
  margin-top: @spacer-xxs;
  text-align: center;
}

.secure__icon {
  margin-right: @spacer-xxs;
}

.input-group {
  .giving-form__form-control.form-control {
    width: 100%;
  }
}

.hide-message {
  visibility: hidden;
  margin: 0;
  margin-top: -16px;
}

.giving-form--neo {
  .FieldMessage {
    margin: 0;
    margin-top: -(@spacer-base);
    padding: @spacer-xxs;

    &.hide-message {
      visibility: hidden;
    }


    .message {
      margin: 0;
      width: 100%;
      text-align: left;
    }
  }
}

.disabled {
  &.price-array__option, .price-array__option {
    cursor: not-allowed;
  }
}

.frequency-option {
  margin-bottom: @spacer-xl;
}

.giving-form__cta {
  margin-top: auto;
}

.frequency-title {
  height: 46px;
  line-height: 46px;
  margin: 0 0 24px 0;
  padding: 0;
  text-align: center;
}