.PaymentEFTScreen {

  .input-group--neo {
    height: 63px;
  
    & > span {
      height: 20px;
      display: block;
    }
  }

  .account-type-selection {
    margin-bottom: 5px;
    label {
      display:block;
    }
    .account-type-radio {
      display: inline;

      label {
        display: inline-block;
      }
      label:first-child {
        margin-right: @spacer-base;
      }
      input[type=radio] {
        top: inherit;
    }
    }
  }
}