.move-next {
  .move-enter {
    position: absolute;
    opacity: 0.1;
    left: 100%;
    top: 0;
    z-index: 2;
  }
  
  .move-enter-active {
    opacity: 1;
    left: 0;
    transition: opacity @duration-medium-in, left @duration-medium-in @ease-out-standard;
    z-index: 2;
  }
  
  .move-exit {
    opacity: 1;
    left: 0;
    z-index: 1;
  }
  .move-exit-active {
    opacity: 0;
    left: -100%;
    transition: opacity @duration-medium-in, left @duration-medium-in @ease-out-standard;
    z-index: 1;
  }
}

.move-back {
  .move-enter {
    position: absolute;
    opacity: 0.1;
    left: -100%;
    top: 0;
    z-index: 2;
  }
  
  .move-enter-active {
    opacity: 1;
    left: 0;
    transition: opacity @duration-medium-out @ease-out-standard, left @duration-medium-out @ease-out-standard;
    z-index: 2;
  }
  
  .move-exit {
    opacity: 1;
    left: 0;
    z-index: 1;
  }
  .move-exit-active {
    opacity: 0;
    left: 100%;
    transition: opacity @duration-medium-out @ease-out-standard, left @duration-medium-out @ease-out-standard;
    z-index: 1;
  }
}