.thankyou__screen {
  text-align: center;

  h2 {
    font-size: 1.4em;
    font-weight: 700;
  }

  p {
    line-height: 1.2em;
  }

  .thankyou__order-summary {
    background: #fff;
    border-radius: @border-radius-base;
    box-shadow: 0 10px 20px rgba(141, 141, 141, 0.19), 0 6px 6px rgba(138, 138, 138, 0.23);
    text-align: left;

    .thankyou__order-item {
      display: grid;
      grid-template-columns: 1fr 50px;
      line-height: normal;
      padding: 8px 14px;
      border-bottom: 1px solid #f1f1f1;

      div {

        &:nth-child(2) {
          text-align: right;
        }
      }

      &:last-child {
        border: none;
      }
    }
    
    .thankyou__order-item--total {
      font-weight: 700;
    }
  }
}