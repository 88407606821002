.address-screen__form-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 7px;
  margin: @spacer-loose 0 0 0;

  .wvus-field-address1 {
    grid-column: span 2;
  }

  .input-group--neo {
    height: inherit;
  }

  .address-screen__select-list-wrapper {
    position: relative;

    &:after {
      color: #7d7d7d;
      content: '\25BE';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      margin: auto;
      top: 10px;
      right: 10px;
      z-index: 222;
      pointer-events: none;
    }

    &.has-error {

      .form-control-wrapper {
        
        span {
          display: none;
        }
      }

      select {
        border: 1px solid @wv-web-red;
      }
    }

    &.has-success {
      
      .form-control-wrapper {
        
        span {
          display: none;
        }
      }

      select {
        border: 1px solid @wv-web-green;
      }
    }
  }

  .address-screen__select-list {
    border-radius: 6px;
    height: 43px;
    padding: 6px 25px 6px 12px;
  }
}

.address-screen__error-message {
  text-align: center;
  margin: 0 auto;
  margin-top: @spacer-loose;
  max-width: 230px;
}
