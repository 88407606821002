.MessagePanel {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  z-index: 50;
  background: transparent;
  padding: 50px 20px 20px 20px;
  --message-close-duration: 1500ms;

  .message-content {
    position: relative;
    opacity: 1;
    margin: 0 auto;
    width: 100%;
    box-shadow: 0px 4px 11px -8px #666666;
    font-size: @font-size-centi;
    line-height: @line-height-centi;
  }

  .message-fade-out {
    opacity: 0;
    transition: opacity 1000ms;
  }

  button.close {
    position: absolute;
    display: block;
    right: 0px;
    top: 0px;
    background: transparent;
    border: none;
    color: #333;
    font-size: 18px;
    cursor: pointer;
    padding: 12px;
  }

  .non-alert {
    background: #fff;
    padding: 15px;
    margin-bottom: 24px;
    border: 1px solid transparent;
    border-radius: 6px;

    button.close {
      color: @wv-orange;
      opacity: 1;

      &:hover {
        color: @wv-dark-orange;
      }
    }
  }

}

.message-panel-box {
  position: absolute;
  background: transparent;
}

// .message-panel-box-appear {
//   opacity: 0;
//   transform: scale(0.1);
// }

// .message-panel-box-appear-active {
//   opacity: 1;
//   transform: scale(1);
//   transition: opacity var(--message-close-duration), transform var(--message-close-duration);
// }

// .message-panel-box-appear-done {
//   opacity: 1;
//   transform: scale(1);
// }

.message-panel-box-enter {
  opacity: 0;
  transform: scale(0.1);
  // display: block;
}

.message-panel-box-enter-active {
  opacity: 1;
  // display: block;
  transform: scale(1);
  transition: opacity var(--message-close-duration), transform var(--message-close-duration);
}

.message-panel-box-enter-done {
  display: block;
  opacity: 1;
  display: block;
  transform: scale(1);
}

.message-panel-box-exit {
  opacity: 1;
  display: block;
  transform: scale(1);
}

.message-panel-box-exit-active {
  opacity: 0;
  transform: scale(0.1);
  transition: opacity var(--message-close-duration), transform var(--message-close-duration);
}

.message-panel-box-exit-done {
  display: none;
  transform: scale(0.1);
}
