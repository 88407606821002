@swingLessPath: "../../node_modules/swing-ui/less/";
@bootStrapLessPath: "../../node_modules/swing-ui/vendor/bootstrap/less";
@import "@{swingLessPath}/variables.less";
@import "@{bootStrapLessPath}/mixins.less";
@import "@{bootStrapLessPath}/scaffolding.less";
@import "./animate.less";

body.donate-widget-app {
  padding: 0;
  margin: 0;
  position: relative;
  background: #f5f5f5;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

#wvus-app {
  --widget-width: 320px;
  --widget-height: 330px;
  @import "./app.less";
  @import "./product-screen.less";
  @import "./screens.less";
  @import "./payment-options";
  @import "./credit-card-field.less";
  @import "./eft-field.less";
  @import "./cover-fee.less";
  @import "./message-panel.less";
  @import "./fields.less";
  @import "./address-screen.less";
  @import "./personal-info.less";
  @import "./thankyou-screen.less";
  @import "./promote-monthly.less";
}