.donate-widget__cover-fee-container {
  font-size: @font-size-centi;
  line-height: 1.4em;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0;

  input {
    cursor: pointer;
    margin-right: 4px;
  }

  p {
    font-size: .8em;
    line-height: 1.4em;
    margin: 0;
  }

  label.checkbox {
    display: inline-block;
  }
}

.donate-widget__line-item-container {
  width: 100%;
  margin: @spacer-base auto;
  min-height: 45px;
  font-size: @font-size-centi;
}

.donate-widget__line-item {
  display: grid;
  grid-template-columns: 1fr 3em;
  
  .donate-widget__line-total {
    justify-self: flex-end;
  }
}

.checkbox--neo input:after {
  font-family: "Font Awesome 5 Free";
}
