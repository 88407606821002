.input-group--neo .form-control {
  height: 43px;
}

.price-array--neo .input-group--neo .form-control {
  height: inherit;
}

.input-row {
  display: flex;
  flex-direction: row;
  .form-group + .form-group {
    margin-left: @spacer-tight;
  }
}

.has-error, .shake-hor .has-error {
  animation: shake-horizontal-demand 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@keyframes shake-horizontal-demand {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}


.shake-hor .has-error {
	animation: shake-horizontal-demand-empty 0s;
}

@keyframes shake-horizontal-demand-empty {
}

.payment-field-shimmer {
  margin-bottom: 27px;
}